



var abc=[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,4,8,5,55,84,75,21,45,45,20]
var sd=abc.slice(2,6)


var start=0;
var arr=[]
var end =5
var pagesize=5


const paginate = (array, n) => {
    const pageSize = Math.ceil(array.length / n);
   
    return Array.from({ length: pageSize }, (_, index) => {
      const start = index * n;
      return array.slice(start, start + n);
    });
  };

var strt=0;
var end =10
var cont =10

 var paginations=abc.slice(strt,end)



