const ReviewData=[

    {
        id: 1,
        name: " Anna Feri",
        Date:"a year ago",
        img:"Review/1.png",
        Text:"We all agree that e-cigarettes are significantly less harmful than smoking."      },
  
    {
        id: 2,
        name: " Sheikh Umar",
        Date:"2017-01-01",
        img:"Review/2.png",
        Text:"Great Experience! Good product quality and nice customer dealing. ",
      },
  
    {
        id: 3,
        name: "Ayaz Rao",
        Date:"2017-01-01",
        img:"Review/3.png",
        Text:" Amazing service , 100% genuine brands and liquids. Great customer service.",
      },
    {
        id: 4,
        name: " ANNS SALEEM",
        Date:"2017-01-01",
        img:"Review/4.png",
        Text:"Good customer service authentic products reasonable prices keep it up boys.and thanks osama for kit.",
      },
    {
        id: 5,
        name: " Zaibi Gamer",
        Date:"2017-01-01",
        img:"Review/5.png",
        Text:"Good customer service authentic products reasonable prices keep it up boys.and thanks osama for kit",
      },
    {
        id: 6,
        name: "Shahzad",
        Date:"2017-01-01",
        img:"Review/6.png",
        Text:"So I visited the shope.Finally I want to say One of the most reliable and finest pricing according to the area.",
      },
    
   

  
  
]
export default ReviewData;