import React, { useState,useEffect } from 'react'
import './Footer.css';
import { Link } from "react-router-dom";
import { PoductsApi, ChangeName } from '../Redux/Action';
import { useSelector, useDispatch } from 'react-redux';


import TrackMyOrder from '../../Screens/TrackMyOrder';
import Orderhistory, { Corderhis } from '../../Screens/Orderhistory';

const Footer = () => {

    const [openModal, setOpenModal] = useState(false)
    const dispatch = useDispatch()
    const [searchPro, setSearchPro] = useState("")
    const cartItems = useSelector(state => state.Reducer)
    const DepartmentData = useSelector(state => state.ReducerDepData)
    const lengthItem = cartItems.length
  
    const [X, setX] = useState(false)
    const [toggleLogin, setToggleLogin] = useState(false)
    const [opencart, setOpencart] = useState(false)
    const [depData, setDepData] = useState(null);
    const [ depHading, setDepHading ] = useState();
    const [ sabdepHading, setSabdepHading ] = useState();
    
  
    
    
  
    useEffect(() => {
      if (DepartmentData !== 'abc') {
        const updatedData = DepartmentData.map((item) => {
          item.isShown = false;
          return { ...item }
        })
        setDepData(updatedData)
      }
    }, [DepartmentData])

    return (
        <>


             <section className='footer-bgimg'>
                <div className='main-footer'>


                    <div>
                        <img src='footer.png' alt='footer' className='footlogo'/>
                        <p >Follow Us</p>

                        <ul className="marginleft">
                            <li className="socialicons"><a target="_blank" href="https://www.facebook.com/vapehouse.pk/"><img src="https://res.cloudinary.com/vape-house/image/upload/v1702742303/facebook_y5ej5t.png" alt='facebook' className='ico'/></a></li>
                            <li className="socialicons"><a target="_blank" href="https://www.instagram.com/invites/contact/?i=tt3bt41t2hlh&amp;utm_content=47kggkv"><img className='ico' alt='instagram' src="https://res.cloudinary.com/vape-house/image/upload/v1702742475/instagram_krtghl.png"/></a></li>
                            {/* <li className="socialicons"><a target="_blank" href="#"><img src="youtube.png" className='ico'/></a></li> */}
                            <li className="socialicons"><a target="_blank" href="//api.whatsapp.com/send/?phone=923006800812&text=Welcome&app_absent=0"><img src="https://res.cloudinary.com/vape-house/image/upload/v1702742275/whatsapp_rx6jvs.png" alt='whatsapp'  className='ico'/></a></li>
                            <li className="socialicons"><a target="_blank" href="tel:+923006800812"><img src="https://res.cloudinary.com/vape-house/image/upload/v1702742335/telephone_ujuaeu.png" alt='telephone' className='ico'/></a></li>
                            {/* <li className="socialicons"><a target="_blank" href=" https://mail.google.com/mail/u/0/#inbox?compose=CllgCJfsctXltgkfgXmXWGVHKCZtHnmCKhzfXTnkRLrvSDGXfZnnXsBLDfGpBWwvBKHptCKPsJB"><img src="email.png" className='ico'/></a></li> */}
                            


                        </ul>

                    </div>
                    <div>
                        <h3> CATEGORIES</h3>
                            <ul className='QuickLinks' style={{fontSize:"16px", textTransform:"lowercase"}} >
                                <li>                {
          depData?.map((item, index) => {
            return (
              <Link  to={'/original-vape-products-in-pakistan'} 
             
                onClick={(e) => {
                  var data = depData[index].value = item.DepartmentName;
                  var data4={
                    department:data,
    

                }
                  dispatch(ChangeName(data))
                  var deptt = depData[index].value = item.DepartmentName;
                  var dep = depData[index].value = item.DepartmentCode;
                  dispatch(PoductsApi(dep, ''))
                  e.stopPropagation();
         
                  { window.scrollTo(0, 0) } }}>
                <p>{`${item.DepartmentName.toUpperCase()}`}</p>
                <span style={{ position: 'absolute' }} className='sab_menu'>
                  {
                    item.Groups.map((it) => {
                      return (
                        item.isShown ?
                          <Link to={'/original-vape-products-in-pakistan'}  onClick={(e) => {
                            var data1 = depData[index].value = item.DepartmentName;
                            var depsab = depData[index].value = it.GroupName;
                            var data =  data1 +" >> "+depsab
                            var data4={
                                department:data1,
                                group:depsab

                            }
                           
                            var dep = depData[index].value = item.DepartmentCode;
                            var grup = depData[index].value = it.GroupCode;
                            dispatch(PoductsApi(dep, grup));
                            dispatch(ChangeName(data4))
                            e.stopPropagation();
                          }} >
                            <p style={{fontSize:"16px"}} >{it.GroupName.substring(0,1).toUpperCase() + it.GroupName.substring(2,it.GroupName.length)}</p>
                          </Link>
                          : null
                      )
                    })
                  }
                </span>
              </Link>
              
            )
          })
        }</li>
                            </ul>               
        
                    </div>

                    <div>
                        <h3> INFORMATION </h3>
                        <ul style={{fontSize:"16px"}} >
                            <li>
                                <Link to={'/about-us'} onClick={() => { window.scrollTo(0, 0) }}>About Us</Link>
                            </li>
                            <li>
                                <Link to={'/contact-us'} onClick={() => { window.scrollTo(0, 0) }}>Contact Us</Link>
                            </li>
                            <li>
                                <Link to={'/privacy-policy'} onClick={() => { window.scrollTo(0, 0) }}>Privacy Policy</Link>
                            </li>
                            <li>
                                <Link to={'/term-condition'} onClick={() => { window.scrollTo(0, 0) }}>Terms & Conditions</Link>
                            </li>
                            <li>
                                <Link to={'/delivery-info'} onClick={() => { window.scrollTo(0, 0) }}>Delivery Information</Link>
                            </li>
                        </ul>

                    </div>
                    

                    
                    <div >
                        <h3> QUICK LINKS </h3>
                        <ul style={{fontSize:"16px"}} >
                            <li>
                                <Link to={'/viewcart'} onClick={() => { window.scrollTo(0, 0) }}   > View Cart</Link>
                            </li>
                            <li>
                                <p className='track-myorder' onClick={() => setOpenModal(!openModal)}>
                                    Track My Order
                                </p>
                                <span style={{ position: 'relative' }} >

                                    <span className='open-ordertrack' style={{ display: openModal ? 'flex' : 'none', }}  >
                                        <TrackMyOrder closeModal={setOpenModal} />
                                    </span>
                                </span>
                            </li>
                            <li>
                                <Link to={'/checkout'} onClick={() => { window.scrollTo(0, 0) }}> Checkout</Link>
                            </li>
                            <li>
                                <Link to={'/order-history'} target='_blank' > Order History </Link>
                            </li>
                            <li >
                             <a href='https://drive.google.com/uc?export=download&id=1N13OvpF7fDSmiPXh1EPeaMg28GR-VtMs'>  <img alt='playstore' width="120px" height="40px"  src='https://res.cloudinary.com/vape-house/image/upload/v1702742327/playstore_yfhosa.png'/></a>
                            </li>
                            <li >
                             <a href='https://www.apple.com/app-store/'>  <img alt='appstore' width="120px" height="40px"  src='https://res.cloudinary.com/vape-house/image/upload/v1702743228/appstore_ihgglf.png'/></a>
                            </li>

                        </ul>
                    </div>



                </div>
                <div className='information'>
                    <h2 className='text-light text-center'>Vape House – Surveyors of Fine Electronic Cigarettes and Supplies
</h2>               <p className="px-5 paragraph " style={{fontSize:"16px"}}>thevapehouse.pk Is One Of The Largest Online Vape store In Pakistan, Having The Largest Variety Of Original Vapes , Eliquids And Vape Accessories.</p>
                </div>
                
                <div className='copy-rights'>
                    <p style={{color:"black"}}>
                          © 2023 All Rights Reserved - Powered by
                        <a href='https://www.softvalley.com.pk/' target='_blank'> Softvalley</a>  
                    </p>
                </div>

            </section> 
        </>
    )
}

export default Footer;