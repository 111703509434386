import React, { useEffect, useState } from 'react';
import './Body.css';
import {  MdShoppingCart } from "react-icons/md";
import ClipLoader from "react-spinners/ClipLoader";
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AddToCart, setItemData } from '../Redux/Action';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Allapiproduct( props) {




    const hddispatch = useDispatch()

    const allproductapi = useSelector(state => state.ReducerAllProducts)
    const dispatch = useDispatch()
    const [loader, setLoader] = useState(true);
    const [proData, setProData] = useState();
    let [counter, setCounter] = useState(1);

    
    const increment = (item, index) => {
        if (item.qty < 10) {
            proData[index].qty += 1
            const updatedData = [...proData]
            setProData(updatedData)
        }
    }
    const decrement = (item, index) => {
        if (item.qty > 1) {
            proData[index].qty -= 1
            const updatedData = [...proData]
            setProData(updatedData)
        }
    }

    useEffect(() => {



        if (allproductapi != 'abc') {
            setProData(allproductapi)
        
        }


    }, [allproductapi])

    return (
        <>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                theme="colored"
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />


            <div className='product-main-body' style={{ boder: "2px solid green" }} >


                {
                    proData ?

                        proData?.map((item, index) => {
                            return (



                                <div className='product_container1'>

                                    {
                                        item.PromoPercentage ?
                                            <span className='discount-offers'>  {item.PromoPercentage}% OFF</span> : ""

                                    }
                                    <Link to={'/product-detail'} state={{ item }} onClick={() => { window.scrollTo(0, 0);
                                        //  console.warn("this is the object details" + item);
                                          dispatch(setItemData(item)) }}>
                                        <img alt={item.Description} src={`${window.imagesapi}/images/ProductImages/${item.Barcode}.webp`} />
                                    </Link>

                                    <p className='P-product' style={{ marginTop: "10px" }}>{item.Description}</p>

                                    <span className='cr-price' style={{ marginTop: "10px" }}>Rs. {item.UnitRetail - (item.UnitRetail * item.PromoPercentage / 100)}

                                        {item.PromoPercentage ?
                                            <del className="oldPrice">{item.PromoPercentage ?`Rs:${item.UnitRetail}`  : null}</del>:null
                                        }</span>
                                    <div className='product-cart'>
                          
                                        <div className='product-cart2' >
                                            <button style={{ border: '1px solid #F5801E' }} onClick={() => {
                                               
                                                dispatch(AddToCart(item))
                                                toast.success(' Product Added Successfully ', {
                                                    position: "top-center",
                                                    autoClose: 1000,
                                                    hideProgressBar: false,
                                                    closeOnClick: true,
                                                    pauseOnHover: true,
                                                    draggable: true,
                                                    progress: undefined,
                                                })
                                            }}>
                                                <MdShoppingCart />
                                            </button>
                                        </div>


                                    </div>

                                </div>



                            )
                        }) :
                        < ClipLoader color={'red'} loading={true} size={100} />

                }

            </div>

            
        </>
    );
}


export default Allapiproduct;

