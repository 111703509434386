import React, { useState } from 'react'

const Maps = () => {

const [imgLhr,setLhr]=useState(true)
const [imgGjr,setGjr]=useState(false)
const [imgMal,setMal]=useState(false)
const [imgMul,setMul]=useState(false)
const [imgSil,setSil]=useState(false)
const [imgall,setImagAll]=useState("https://res.cloudinary.com/vape-house/image/upload/v1702739013/vapehouse-lhr1_dejiik.jpg")


const imageArry=[
    { Gujranwala:"https://res.cloudinary.com/vape-house/image/upload/v1702736379/Gujranwala_dklzb5.jpg"},
    {sialkot:"https://res.cloudinary.com/vape-house/image/upload/v1702736391/sialkot_hl7g0w.jpg"},
    {Multan:"https://res.cloudinary.com/vape-house/image/upload/v1702736218/Multan_huuevd.jpg"},
    {Mallroad:"https://res.cloudinary.com/vape-house/image/upload/v1702735898/Mallroad_awrvhv.jpg"}]

    return (
        <>
            <div className='container-fluid px-5 mb-2'>
                <div className='ariv justify-content-center text-center'>
                    <p>Near to Me</p>
                </div>
                <div className='row' >
                    <ul className="nav" style={{flexWrap: "nowrap", overflow:"hidden"}}>
                        <li className="nav-item">

                            <a className="citi btn btn-outline-warning border-0 nav-link" style={{ cursor: "pointer" }} aria-current="page" onClick={() => {
                               
                               setImagAll("https://res.cloudinary.com/vape-house/image/upload/v1702739013/vapehouse-lhr1_dejiik.jpg")
                               document.getElementById('Lahore').style.display = "flex"
                                document.getElementById('Multan').style.display = "none"
                                document.getElementById('Bahawalpur').style.display = "none"
                                // document.getElementById('Lahoree').style.display = "flex"
                                // document.getElementById('Multane').style.display = "none"
                                // document.getElementById('Bahawalpure').style.display = "none"
                                document.getElementById('SialKot').style.display = "none"
                                // document.getElementById('SialKote').style.display = "none"
                                document.getElementById('Malroad').style.display = "none"
                                // document.getElementById('Malroade').style.display = "none"

                                setGjr(false)
                                setLhr(true)
                                setSil(false)
                                setMal(false)
                                setMul(false)

                            }}
                            >Lahore</a>  </li>
                        <li className="nav-item">
                            <a className="citi btn btn-outline-warning border-0  nav-link" style={{ cursor: "pointer" }} onClick={() => {
setImagAll("https://res.cloudinary.com/vape-house/image/upload/v1702736379/Gujranwala_dklzb5.jpg")
                                document.getElementById('Lahore').style.display = "none"
                                document.getElementById('Multan').style.display = "none"
                                document.getElementById('Bahawalpur').style.display = "flex"
                                // document.getElementById('Lahoree').style.display = "none"
                                // document.getElementById('Multane').style.display = "none"
                                // document.getElementById('Bahawalpure').style.display = "flex"
                                // document.getElementById('SialKote').style.display = "none"
                                document.getElementById('SialKot').style.display = "none"
                                document.getElementById('Malroad').style.display = "none"
                                // document.getElementById('Malroade').style.display = "none"
                                setGjr(true)
                                setLhr(false)
                                setSil(false)
                                setMal(false)
                                setMul(false)

                            }}
                            >Gujranwala</a>  </li>
                        <li className="nav-item">
                    
                            <a className="citi nav-link btn btn-outline-warning border-0" style={{ cursor: "pointer" }} onClick={() => {
                                document.getElementById('Lahore').style.display = "none"
                                document.getElementById('Multan').style.display = "flex"
                                document.getElementById('Bahawalpur').style.display = "none"
                                // document.getElementById('Lahoree').style.display = "none"
                                // document.getElementById('Multane').style.display = "flex"
                                // document.getElementById('Bahawalpure').style.display = "none"
                                // document.getElementById('SialKote').style.display = "none"
                                document.getElementById('SialKot').style.display = "none"
                                document.getElementById('Malroad').style.display = "none"
                                // document.getElementById('Malroade').style.display = "none"
                                setGjr(false)
                                setImagAll("https://res.cloudinary.com/vape-house/image/upload/v1702736218/Multan_huuevd.jpg")
                                setLhr(false)
                                setSil(false)
                                setMal(false)
                                setMul(true)

                            }}
                            >Multan</a>  </li>
                        <li className="nav-item">
                            <a className="citi nav-link btn btn-outline-warning border-0" style={{ cursor: "pointer" }} onClick={() => {
                                document.getElementById('Multan').style.display = "none"
                                document.getElementById('Lahore').style.display = "none"
                                document.getElementById('SialKot').style.display = "flex"
                                document.getElementById('Bahawalpur').style.display = "none"
                                // document.getElementById('Lahoree').style.display = "none"
                                // document.getElementById('SialKote').style.display = "flex"
                                // document.getElementById('Bahawalpure').style.display = "none"
                                // document.getElementById('Multane').style.display = "none"
                                document.getElementById('Malroad').style.display = "none"
                                // document.getElementById('Malroade').style.display = "none"
                                setGjr(false)
                                setImagAll("https://res.cloudinary.com/vape-house/image/upload/v1702736391/sialkot_hl7g0w.jpg")
                                setLhr(false)
                                setSil(true)
                                setMal(false)
                                setMul(false)
                            }}>Sialkot</a>
                        </li>


                        <li className="nav-item">
                            <a className="citi nav-link btn btn-outline-warning border-0" style={{ cursor: "pointer" }} onClick={() => {
                                document.getElementById('Multan').style.display = "none"
                                document.getElementById('Lahore').style.display = "none"
                                document.getElementById('SialKot').style.display = "none"
                                document.getElementById('Bahawalpur').style.display = "none"
                                // document.getElementById('Lahoree').style.display = "none"
                                // document.getElementById('SialKote').style.display = "none"
                                // document.getElementById('Bahawalpure').style.display = "none"
                                // document.getElementById('Multane').style.display = "none"

                                document.getElementById('Malroad').style.display = "flex"
                                // document.getElementById('Malroade').style.display = "flex"
                                setGjr(false)
                                setLhr(false)

setImagAll("https://res.cloudinary.com/vape-house/image/upload/v1702735898/Mallroad_awrvhv.jpg")
                                setSil(false)
                                setMal(true)
                                setMul(false)
                            }}>Mallroad</a>
                            </li>
                    </ul>

                </div>
                <div className='row mt-3'>
                    <div className='col-md-6' >
                        <div id='Bahawalpure' style={{display: imgLhr?"flex":"none"}}>
                        <img src={ imgall}  style={{ display: "flex", overflow: "hidden", width: "100%", height: "370px", marginBottom:"10px"}} />

                        </div>

                        <div id='Sialkote' style={{display: imgSil?"flex":"none"}}>
                        <img src={imgall}  style={{ display: "flex", overflow: "hidden", width: "100%", height: "370px", marginBottom:"10px" }} />

                        </div>

                        <div id='Bahawalpure' style={{display: imgGjr?"flex":"none"}}>
                        <img src={ imgall}  style={{ display: "flex", overflow: "hidden", width: "100%", height: "370px", marginBottom:"10px"}} />

                        </div>
<div id='Malroade' style={{display: imgMal?"flex":"none"}}>
<img src={imgall}  style={{ display: "flex", overflow: "hidden", width: "100%", height: "370px", marginBottom:"10px" }} />

</div>

<div id='Multane' style={{display: imgMul?"flex":"none"}}>
<img src={ imgall}  style={{ display: "flex", overflow: "hidden", width: "100%", height: "370px", marginBottom:"10px" }} />

</div>



                    </div>
                    <div className='col-md-6 '>
                    <div id='Malroad' style={{ display: "none" }} className="" >
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d26912.142445067824!2d74.53085929481063!3d32.52567140381054!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x391eeb7fde8898d3%3A0x80d5b93f725c25b5!2sThe%20Vape%20House%20Sialkot!5e0!3m2!1sen!2s!4v1673345567958!5m2!1sen!2s"
                                allowfullscreen="" loading="lazy" style={{ width: "100%", height: "370px", border: 0, boxShadow: "rgb(0 0 0 / 16%) 0px 3px 6px, rgb(0 0 0 / 23%) 0px 3px 6px" }}></iframe>
                        </div>
                        <div id='Multan' style={{ display: "none", overflow: "hidden" }} className="">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d55160.307346356014!2d71.473765!3d30.222267!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xdcd7261ebae41557!2sTHE%20VAPEHOUSE%20(MULTAN)%20Vape%20Shop%20-%20Electronic%20Cigarettes%20Ecig%20Kits%20Mod%20E-liquids!5e0!3m2!1sen!2sus!4v1659790126931!5m2!1sen!2sus"
                                allowfullscreen="" loading="lazy" style={{ width: "100%", height: "370px", border: 0, boxShadow: "rgb(0 0 0 / 16%) 0px 3px 6px, rgb(0 0 0 / 23%) 0px 3px 6px" }}></iframe>
                        </div>







                        <div id='Lahore' className="" >
                            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d436209.6921644023!2d74.607888!3d31.33446!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x391905dbd1b429af%3A0x7624e0403b8997e4!2sTHE%20VAPEHOUSE%20(MODEL%20TOWN)-(LHR)%20Vape%20Shop%20-%20Electronic%20Cigarettes%20Ecig%20Kits%20Mod%20E-liquids!5e0!3m2!1sen!2sus!4v1659789878080!5m2!1sen!2sus"
                                allowfullscreen="" loading="lazy" style={{ width: "100%", height: "370px", border: 0, boxShadow: "rgb(0 0 0 / 16%) 0px 3px 6px, rgb(0 0 0 / 23%) 0px 3px 6px" }}></iframe>
                        </div>

                        <div id='Bahawalpur' style={{ display: "none" }} className="" >
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d27231.284812495385!2d74.24584521562501!3d31.444128000000017!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3919010fbc06d17d%3A0xc86c774caaa9c4e4!2sTHE%20VAPEHOUSE%20BAHAWALPUR!5e0!3m2!1sen!2s!4v1675264808989!5m2!1sen!2s"
                                allowfullscreen="" loading="lazy" style={{ width: "100%", height: "370px", border: 0, boxShadow: "rgb(0 0 0 / 16%) 0px 3px 6px, rgb(0 0 0 / 23%) 0px 3px 6px" }}></iframe>
                        </div>


                        <div id='SialKot' style={{ display: "none" }} className="" >
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d26912.142445067824!2d74.53085929481063!3d32.52567140381054!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x391eeb7fde8898d3%3A0x80d5b93f725c25b5!2sThe%20Vape%20House%20Sialkot!5e0!3m2!1sen!2s!4v1673345567958!5m2!1sen!2s"
                                allowfullscreen="" loading="lazy" style={{ width: "100%", height: "370px", border: 0, boxShadow: "rgb(0 0 0 / 16%) 0px 3px 6px, rgb(0 0 0 / 23%) 0px 3px 6px" }}></iframe>
                        </div>
                       
                    </div>
                </div>
            </div>

        </>
    )
}

export default Maps
