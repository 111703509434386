
import React from 'react';

function Googlemap1(props) {
    return (
        <div >
        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d55160.307346356014!2d71.473765!3d30.222267!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xdcd7261ebae41557!2sTHE%20VAPEHOUSE%20(MULTAN)%20Vape%20Shop%20-%20Electronic%20Cigarettes%20Ecig%20Kits%20Mod%20E-liquids!5e0!3m2!1sen!2sus!4v1659790126931!5m2!1sen!2sus"
         allowfullscreen="" loading="lazy" style= {{width:"95%",  height:"300px", border:0, boxShadow:"rgb(0 0 0 / 16%) 0px 3px 6px, rgb(0 0 0 / 23%) 0px 3px 6px" }}></iframe>
            </div>
    );
}


function Googlemap2(props) {
    return (
        <div >

src= 
        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d54434.80813944268!2d74.29887869711273!3d31.491859302353447!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x391905dbd1b429af%3A0x7624e0403b8997e4!2sTHE%20VAPEHOUSE%20(MODEL%20TOWN)-(LAHORE)%20Best%20Vape%20Shop%20-%20Electronic%20Cigarettes%20Ecig%20Kits%20Mod%20E-liquids!5e0!3m2!1sen!2sus!4v1699098333295!5m2!1sen!2sus"
           allowfullscreen="" loading="lazy" style= {{width:"100%",  height:"300px", border:0,  boxShadow:"rgb(0 0 0 / 16%) 0px 3px 6px, rgb(0 0 0 / 23%) 0px 3px 6px" }}></iframe>
        </div>
    );
}
function Googlemap3(props) {
    return (
        <div >
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d27231.284812495385!2d74.24584521562501!3d31.444128000000017!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3919010fbc06d17d%3A0xc86c774caaa9c4e4!2sTHE%20VAPEHOUSE%20BAHAWALPUR!5e0!3m2!1sen!2s!4v1675264808989!5m2!1sen!2s"
         allowfullscreen="" loading="lazy" style= {{width:"95%",  height:"300px", border:0,  boxShadow:"rgb(0 0 0 / 16%) 0px 3px 6px, rgb(0 0 0 / 23%) 0px 3px 6px" }}></iframe>
        {/* <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6863.345241846026!2d73.111597!3d30.671346!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x1ec18141a575916b!2sTHE%20VAPEHOUSE%20(SAHIWAL)%20Vape%20Shop%20-%20Electronic%20Cigarettes%20Ecig%20Kits%20Mod%20E-liquids!5e0!3m2!1sen!2sus!4v1659790654610!5m2!1sen!2sus" 
           allowfullscreen="" loading="lazy" style= {{width:"95%",  height:"300px", border:0,  boxShadow:"rgb(0 0 0 / 16%) 0px 3px 6px, rgb(0 0 0 / 23%) 0px 3px 6px" }}></iframe> */}
        
        </div>
    );

}



function Googlemap4(props) {
    return (
        <div >
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d26912.142445067824!2d74.53085929481063!3d32.52567140381054!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x391eeb7fde8898d3%3A0x80d5b93f725c25b5!2sThe%20Vape%20House%20Sialkot!5e0!3m2!1sen!2s!4v1673345567958!5m2!1sen!2s" 
           allowfullscreen="" loading="lazy" style= {{width:"95%",  height:"300px", border:0,  boxShadow:"rgb(0 0 0 / 16%) 0px 3px 6px, rgb(0 0 0 / 23%) 0px 3px 6px" }}></iframe>
        </div>
    );
    
}



export {Googlemap1, Googlemap2,Googlemap3,Googlemap4};

