import React from 'react'
import Slider from 'react-slick'
const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    arrows: false,
    responsive: [
        {
            breakpoint: 1536,
            settings: {
                slidesToShow: 6,
            },
        },
        {
            breakpoint: 1400,
            settings: {
                slidesToShow: 6,
            },
        },
        {
            breakpoint: 1280,
            settings: {
                slidesToShow: 6,
            },
        },
        {
            breakpoint: 1024,
            settings: {

                slidesToShow: 6,
            },
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 4,
            },
        },
        {
            breakpoint: 500,
            settings: {

                slidesToShow: 3,
            },
        },
        {
            breakpoint: 400,
            settings: {

                slidesToShow: 3,
            },
        },
    ],
}



const Brands = () => {
    return (
        <>
            <div className="container-fluid brands" >
                <div className='container mt-5'>
                    <div data-aos="zoom-in" data-aos-duration="500" className="row  ">
                        {/* <div className="col-md-2 brands ">
                            <div className="Line "></div>
                        </div> */}
                        <div className="col-md-12">
                            <h1 className="text-center arrival">TOP BRANDS IN THE VAPING WORLD</h1>
                            <p style={{fontSize:16}}>
                            We offer freebase and nicotine salt e-liquids from all major brands, including VGOD, 7 Daze, Pod Salt, Naked100, Dinner Lady, IVG, VEIIK, and many more, to cater to a broader vaping audience. Additionally, we bring some of the best vape device brands in Pakistan, such as Smok, Uwell, GeekVape, FreeMax, Innokin, JustFog, Suorin, Vaporesso, VooPoo, Aspire, Relx, Myle, Juul, and many others.
   
                            </p>
                        </div>
                        {/* <div className="col-md-2 brands">
                            <div className="Line "></div>

                        </div> */}
                    </div>
                </div>


                <div className="col-md-12 col-sm-12 col-xs-12 feature-seating">
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-xs-12 text-center  ">
                            <Slider {...settings}>
                                <a className='brands'>
                                    <img className="zoom-eff" alt="BLVK" src="https://res.cloudinary.com/vape-house/image/upload/v1702740887/9_qrs375.jpg" />
                                </a>
                                <a className='brands'>
                                    <img className="zoom-eff" alt="VGOD" src="https://res.cloudinary.com/vape-house/image/upload/v1702740884/8_wtvy9v.jpg" />
                                </a>
                                <a className='brands'>
                                    <img className="zoom-eff" alt="POP" src="https://res.cloudinary.com/vape-house/image/upload/v1702740881/7_civbht.jpg" />
                                </a>
                                <a className='brands'>
                                    <img className="zoom-eff" alt="ZENITH" src="https://res.cloudinary.com/vape-house/image/upload/v1702740879/6_xwiryd.jpg" />
                                </a>
                                <a className='brands'>
                                    <img className="zoom-eff" alt="NASTY" src="https://res.cloudinary.com/vape-house/image/upload/v1702740876/5_qprx6y.jpg" />
                                </a>
                                <a className='brands'>
                                    <img className="zoom-eff" alt="SAD BOY" src="https://res.cloudinary.com/vape-house/image/upload/v1702740872/4_esve0x.jpg" />
                                </a>
                                <a className='brands'>
                                    <img className="zoom-eff" alt="POP" src="https://res.cloudinary.com/vape-house/image/upload/v1702740868/3_ejijjk.jpg" />
                                </a>
                                <a className='brands'>
                                    <img className="zoom-eff" alt="ZENITH" src="https://res.cloudinary.com/vape-house/image/upload/v1702740857/2_rar7zp.jpg" />
                                </a>
                                <a className='brands'>
                                    <img className="zoom-eff" alt="NASTY" src="https://res.cloudinary.com/vape-house/image/upload/v1702740852/1_hknlps.jpg" />
                                </a>
                            </Slider>
                        </div>



                    </div>

                </div>




            </div>
        </>
    )
}

export default Brands
