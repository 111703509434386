import React,{useEffect,useState} from 'react'
import Header from '../components/Header/Header';
import Body from '../components/Body/Body';
import Footer from '../components/Footer/Footer';
import  ReactDOM  from 'react-dom';
import PaginatedItems from "./prectic"
import NavBar from './NavBar';
import { Button } from 'react-bootstrap';


const Home = () => {

  return (
    <>
    {/* <NavBar /> */}
    
    {/* Commit by HMS */}
    <Header id='windowclose' />


    {/* <PaginatedItem> */}
    

    <div id='container'></div>
    <Body />
  
    <Footer />
    </>
  )
}

export default Home;
