
import './Otpmodal.css';
import OtpInput from "react-otp-input";
import React, { useState } from "react";
import { GiCrossedBones } from 'react-icons/gi';

const Otpmodal = (props) => {
  const [code, setCode] = useState("");
  const handleChange = (code) => setCode(code);


  const otpMobile = () => {
    if (window.otpphone === code ||  window.otpfoget === code) {
      props.closeOtpmodal(false)
      document.getElementById("geuserdata").style.display="block";
      document.getElementById("getotp").style.display="none";
    } else {
      alert(window.otpMobile + code);
    }
  }

  return (
    <div className="otpmodal">
      
      <div className="main-div">
      <span>
        <button className='cancel-btnn' onClick={()=>props.closeOtpmodal(false)}> <GiCrossedBones />  </button>
      </span>
        <h1 className='center-div'>Enter verification code</h1>
        <div className='center-div'>
          <OtpInput
            value={code}
            onChange={handleChange}
            numInputs={8}
            separator={<span style={{ width: "8px" }}></span>}
            isInputNum={true}
            shouldAutoFocus={true}
            inputStyle={{
              border: "1px solid rgb(3, 86, 156)",
              borderRadius: "8px",
              width: "54px",
              height: "54px",
              fontSize: "20px",
              color: "#000",
              fontWeight: "400",
              caretColor: "blue"
            }}
            focusStyle={{
              border: "1px solid #CFD3DB",
              outline: "none"
            }}
          />
        </div>
        <div className='center-div'>
          <button className='buttonn' onClick={otpMobile}>Submit</button>
        </div>
      </div>
    </div>
  );
};
export default  Otpmodal;
