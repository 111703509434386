import React, { useEffect, useState } from 'react';
import './ProductsPage.css';
import "./../components/Body/Body.css"
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import { Link, useLocation } from 'react-router-dom';
import Allapiproduct from '../components/Body/Allapiproduct';
import { PoductsApi, ChangeName, MoreData, setItemData, AddToCart } from '../components/Redux/Action';
import { useSelector, useDispatch } from 'react-redux';
import ClipLoader from "react-spinners/ClipLoader";

import { Helmet } from "react-helmet";
import { MdShoppingCart } from "react-icons/md";


const BrandProduct = () => {

    const hddispatch = useDispatch()
    const DepartmentData = useSelector(state => state.ReducerDepData)
    const Changename = useSelector(state => state.ReducerChangeName)
    const pFullD = useSelector(state => state.ReducerMoreData)
    const brandInfo = useSelector(state => state.ReducerBrandProducts)
    const [depData, setDepData] = useState(null);
    const [MData, setMDate] = useState([])
    const [vegproDatamore, setVegproDatamore] = useState()
    const [count, setCount] = useState(0)
    const [Last, setLast] = useState(10)
    const [code, setCode] = useState(localStorage.getItem("code"))
    const [Value, setValue] = useState(null)
    useEffect(() => {
        if (brandInfo != "brand info") {
        }

    }, [brandInfo])


    function brandFullData() {
        const url_full = `${window.sbapi}/api/product/ProductsByBrand?BrandCode=&FromIndex=1&ToIndex=20&BusinessId=0000000001`;
        code === 'all-data' ?

            fetch(url_full).then(res => res.json()).then(results => {
                const brandData = results.Data?.map((item) => {
                    item.qty = 1
                    return { ...item }
                })
                setVegproDatamore(brandData)
            }) :
            fetch(`${window.sbapi}/api/product/ProductsByBrand?BrandCode=${code}&FromIndex=1&ToIndex=20&BusinessId=0000000001`).then(res => res.json()).then(results => {
                const brandData = results.Data?.map((item) => {
                    item.qty = 1
                    return { ...item }
                })
                setVegproDatamore(brandData)
            })
    }












    function loadMoreD(dep, grup) {
        // console.warn(dep + grup + 'product more data' + Changename)
        if (count == 0) {
            fetch(`${window.sbapi}//api/product/ProductsByBrand?BrandCode=&FromIndex=1&ToIndex=200&BusinessId=0000000001`)
                .then(res => res.json())

                .then(json => {
                    const data = json.Data.map((item) => {
                        item.qty = 1
                        return { ...item }
                    })
                    setMDate(data)
                    hddispatch(MoreData(data))
                    // console.warn(MData);
                    var dataml = MData.slice(0, 5)
                    setVegproDatamore(dataml)
                    setCount(count + 1)
                }
                )
        } else if (count != 0 && count < Math.abs(MData.length / 10)) {
            var start = 0;
            var pdata = MData.slice(start, Last + count * 12);

            setVegproDatamore(pdata)
            // console.warn('data that is render' + pdata.length)

            setCount(count + 1)
        }





    }




    useEffect(() => {

        if (brandInfo !== 'abc') {
            const updatedData = brandInfo.map((item) => {
                item.isShown = false;
                return { ...item }
            })

            setVegproDatamore(updatedData)

        }
    }, [brandInfo])


    useEffect(() => {

        if (DepartmentData !== 'abc') {
            const updatedData = DepartmentData.map((item) => {
                item.isShown = false;
                return { ...item }
            })

            setDepData(updatedData)

        }

    }, [DepartmentData])




    return (

        <>
            <Helmet>
                <meta name="description" content="Vape House offers wide variety of E-Liquids Including All The Branded E-Liquids And Much More..Please Click On This Link For More" />
                <title>Vape House offers wide variety of E-Liquids Including All The Branded E-Liquids And Much More.</title>
            </Helmet>

            <Header id='windowclose'/>

            <div className="productspage">
                <div className="slider-bar">
                    <div className='slidebar_conatainer'>
                        {

                            depData?.map((item, index) => {
                                return (

                                    <Link to={'/productspage'} className='menu_container2'
                                        onMouseOver={() => {
                                            depData[index].isShown = true
                                            setDepData([...depData])
                                        }}
                                        onMouseLeave={() => {
                                            depData[index].isShown = false
                                            setDepData([...depData])
                                        }}

                                        onClick={(e) => {
                                            setValue(depData[index].value = item.DepartmentCode)
                                            var dep = depData[index].value = item.DepartmentCode;

                                            hddispatch(ChangeName({
                                                department: dep,

                                            }))

                                            e.stopPropagation();
                                            hddispatch(PoductsApi(dep, ''));

                                        }
                                        }

                                    >

                                        <p>{item.DepartmentName}</p>

                                        <span className='sab_menu2'>
                                            {
                                                item.Groups.map((it) => {
                                                    return (
                                                        item.isShown ?

                                                            <Link to={'/productspage'} onClick={(e) => {
                                                                var dep = depData[index].value = item.DepartmentCode;
                                                                var grup = depData[index].value = it.GroupCode;

                                                                hddispatch(PoductsApi(dep, grup));
                                                                hddispatch(ChangeName({
                                                                    department: dep,
                                                                    group: grup

                                                                }))
                                                                e.stopPropagation();
                                                            }} >
                                                                <p>{it.GroupName}</p>
                                                            </Link>
                                                            : null
                                                    )
                                                })
                                            }
                                        </span>
                                    </Link>
                                )
                            })
                        }
                    </div>
                </div>











                <div className="products">

                    <h1>BRANDS</h1>
                    <div className='product-main-body' style={{ backgroundColor: "white", marginTop: -20, paddingTop: 50 }}>
                        {
                            vegproDatamore ?

                                vegproDatamore?.map((item, index) => {
                                    return (

                                        <div className='product_container1' >
                                            <Link to={'/product-detail'} state={{ item }} onClick={() => { window.scrollTo(0, 0); hddispatch(setItemData(item)) }}>
                                                <img alt={item.Description} src={`${window.imagesapi}/images/ProductImages/${item.Barcode}.webp`} />
                                            </Link>

                                            <div className='P-product' style={{ color: "black", overflow: "hidden" }}>{item.Description}</div>

                                            <span className='cr-price'>Rs.&nbsp;{item.UnitRetail}</span>
                                            <div className='product-cart'>

                                                <div className='product-cart2'>
                                                    <button style={{ border: "1px solid #F5801E" }} onClick={() => {
                                                        hddispatch(AddToCart(item));
                                                        alert("Product Added Successfully")

                                                    }}>
                                                        <MdShoppingCart />

                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                    )
                                }) :
                                <div style={{ display: count >= 1 ? "block" : "none" }}>
                                    < ClipLoader color={'red'} loading={true} size={100} />
                                </div>



                        }
                    </div>






                    <div className='load'><button style={{ border: "0.1px solid", fontSize: 16, marginTop: -100 }} className={"load-More"} onClick={() => {
                        loadMoreD(Changename.Dcode, Changename.Gcode);

                    }}> Load More</button> </div>
                </div>




            </div>

            <Footer />

        </>
    )
}




export default BrandProduct;