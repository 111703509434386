import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../components/Body/Body.css';
import { MdShoppingCart } from "react-icons/md";
import ClipLoader from "react-spinners/ClipLoader";
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AddToCart, SetQty, setItemData } from '../components/Redux/Action';
import { HiMinusSm, HiPlusSm } from 'react-icons/hi';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Promotion = ({ proData, setProData }) => {
    const [prodata, setProdata] = useState();

    const PromotionNewArival=useSelector(state=>state?.Reducerprodata )
    const getBrand = useSelector(state => state.ReducerBrandsData)
    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        pauseOnHover: true,
        arrows: false,
        responsive: [
            {
                breakpoint: 1536,
                settings: {
                    slidesToShow: 5,
                },
            },
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 4,
                },
            },
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 4,
                },
            },
            {
                breakpoint: 1024,
                settings: {

                    slidesToShow: 4,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 500,
                settings: {

                    slidesToShow: 2,
                },
            },
        ],
    }

    const dispatch = useDispatch()
    const [loader, setLoader] = useState(true);



    console.log("DATA IS THE MAIN POIN",PromotionNewArival)
    useEffect(() => {
        // axios.get(`https://easyapivarivh.thevapehouse.pk/api/Product/PromoandNewArrivalProducts?BusinessId=0000000001`)
        //     .then((repss) => {
        //         if (repss.data.Message === 'Success') {
        //             let apidata = repss.data.Data.PromoProducts

        //             setProdata(apidata)

        //         }
        //         else {
        //             alert("error")
        //         }
        //     })


            setProdata(PromotionNewArival?.promotional)

    }, [PromotionNewArival])

    return (
        <>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                theme="colored"
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

            <div className='container mt-5 mb-5 '>
                <div data-aos="fade-up" data-aos-duration="500" className="row">
                    {/* <div className="col-md-4 ">
                        <div className="Line "></div>
                    </div> */}
                    <div className="col-md-10">
                        <h2 className="text-center arrival ">Best Vape Prices in Pakistan </h2>
                    </div>
                    {/* <div className="col-md-3">
                        <div className="Line px-5"></div>

                    </div> */}

                    <Link className="viewall" to="original-vape-products-in-pakistan">
                        <div style={{ fontSize: "16px" }} > view all</div>
                    </Link>
                </div>
            </div>
            <Slider  {...settings} className="mx-2 px-8 mb-5">
                {
                    prodata?.map((item, index) => {
                        return (
                            <div className='product-main-body px-1' >
                                <div className='product_container1' id='slideId' >
                                    {
                                        item.PromoPercentage ?
                                            <span className='discount-offers'>  {item.PromoPercentage}% OFF</span> :
                                            ""
                                    }
                                    <Link to={'/product-detail'} state={{ item }} onClick={() => { window.scrollTo(0, 0) ;   dispatch(setItemData(item))    }}>
                                        <img src={`${window.imagesapi}/images/ProductImages/${item.Barcode}.webp`} />
                                    </Link>

                                    <p className='P-product' style={{ marginTop: "20px" }} >{item.Description}</p>

                                    <span className='cr-price' style={{ marginTop: "10px" }}>Rs. {item.UnitRetail - (item.UnitRetail * item.PromoPercentage / 100)}

                                      

                                       <del className="oldPrice"> {item.PromoPercentage ?`Rs: ${ item.UnitRetail}` : null}</del>          

                                       
</span>
                                    <div className='product-cart' style={{ border: "1px solid #F5801E", borderRadius: "6px" }}>
                                        <div className='product-cart1'>
                                           
                                        </div>
                                        <div className='product-cart2'>
                                            <button onClick={() => {
                                                debugger
                                                dispatch(AddToCart(item))

                                                toast.success('  Product Added Successfully', {
                                                    position: "top-center",
                                                    autoClose: 1000,
                                                    hideProgressBar: false,
                                                    closeOnClick: true,
                                                    pauseOnHover: true,
                                                    draggable: true,
                                                    progress: undefined,
                                                })
                                            }}>
                                                <MdShoppingCart />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        )
                    })

                }
            </Slider>
        </>
    );
};


export default Promotion;