import React from 'react'
import "./ElementVape.css";

const Banner = () => {
  return (
    <>

      <nav className="navbar navbar-expand-lg navbar-dark bg-dark  headingtopbar">
        <div className="container-fluid">

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <marquee scrolldelay="200" className="ms-5" style={{ color: "white", fontSize: "16px" }}><b>Free Shipping</b> on all orders above Rs. 3,000 &nbsp; &nbsp;&nbsp; Coming Soon: <b>Islamabad and Faisalabad</b></marquee>
            </ul>
            <ul className="tophedersocials mt-2">

              <li className="socialicon"><a target="_blank" href="#"><i class="fa fa-map-marker">&nbsp;</i>Store Near Me</a></li>&nbsp;&nbsp;
              <li className="socialicon"><a target="_blank" href="tel:+923006800812"><img src="https://res.cloudinary.com/vape-house/image/upload/v1702742335/telephone_ujuaeu.png" className='icons' />&nbsp;(0300 6800 812)</a></li>&nbsp;&nbsp;
              <li className="socialicon"><a target="_blank" href="https://www.facebook.com/vapehouse.pk/"><img src="https://res.cloudinary.com/vape-house/image/upload/v1702742303/facebook_y5ej5t.png" className='icons' /></a></li>&nbsp;&nbsp;
              <li className="socialicon"><a target="_blank" href="https://www.instagram.com/invites/contact/?i=tt3bt41t2hlh&amp;utm_content=47kggkv"><img src="https://res.cloudinary.com/vape-house/image/upload/v1702742475/instagram_krtghl.png" className='icons' /></a></li>&nbsp;&nbsp;
              {/* <li class="socialicon"><a target="_blank" href="#"><img src="youtube.png" className='icons' /></a></li>&nbsp;&nbsp; */}
              <li className="socialicon"><a target="_blank" href="https://api.whatsapp.com/send/?phone=923006800812&text=Welcome&app_absent=0"><img src="https://res.cloudinary.com/vape-house/image/upload/v1702742275/whatsapp_rx6jvs.png" className='icons' /></a></li>&nbsp;&nbsp;

            </ul>
          </div>
        </div>
      </nav>

    </>
  )
}

export default Banner