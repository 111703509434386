import React from 'react'
import { FaUnderline } from 'react-icons/fa'
import './Common.css'

const Pakistan = (props) => {
  return (
    <>
    {
        props.indi=="1"?<div className='container mt-5' style={{overflow:"hidden"}}>
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 padd-right">
            <div style={{marginBottom:"70px"}}>
                    <h1 data-aos="fade-left" data-aos-duration="1000" className="text-center pakheading">PAKISTAN'S</h1><br/>
                
                    <h3 data-aos="fade-right" data-aos-duration="1000" style={{fontSize:"25px",fontWeight:"1000", marginBottom:"20px", textDecoration:"underline", textDecorationColor:"green", textUnderlinePosition:"under"}} className="text-center">Largest Vape Store Network</h3>
                    
                    

                <p data-aos="zoom-out" data-aos-duration="1000" className="text-center text px-3 " style={{color:"black",backgroundColor:"white",justifyContent:"left", fontSize:"16px",textAlign:"left", lineHeight:"30px"}}>
                <span >With The Vape House being among the first vape stores in Pakistan, we strive to bring the utmost convenience to the vaping community. 
                We have the largest store network with 5+ outlets serving excellence across the country. Get all your vape products from our <strong> Retail Shops </strong> 
                 stationed in all the prime locations of Lahore, Multan, Bahawalpur and Sialkot. So, stop by at any of our The Vape House stores near you and make the healthy transition to vaping today!</span>
                </p>

            </div>
            {/* <div id="" style={{marginBottom:"70px"}}>
                    
                    <h3 data-aos="fade-right" data-aos-duration="1000" style={{fontSize:"25px",fontWeight:"1000", marginBottom:"20px", textDecoration:"underline", textDecorationColor:"green", textUnderlinePosition:"under"}} className="text-center" >LARGEST CHAIN OF VAPE STORES</h3>

                <p data-aos="zoom-out" data-aos-duration="1000" className="text-center text px-3" style={{color:"black",backgroundColor:"white",justifyContent:"left", fontSize:"16px",textAlign:"left", lineHeight:"30px"}}>
                <span >Elite Cigs & Vaporizers is the leading vape store in Pakistan having a chain of outlets in Karachi, Lahore, Multan, Hyderabad and Sialkot. We have the largest stock of vape products from the leading international brands. Our store offer all types of POD MOD and vape juices at discounted prices. We serve only the top quality Ecigs, Vape juices like Vape MODS, portable devices, Kits and all type of accessories at an affordable price. Visit our store today at your city.
<br></br>
<p style={{margin:"20px"}}> Lahore | Multan | Bahawalpur | Sialkot
 | </p></span>
                </p>

            </div> */}
            <div id="pakistan">
                    
                
                    <h3 data-aos="fade-right" data-aos-duration="1000" style={{fontSize:"25px",fontWeight:"1000", marginBottom:"20px", textDecoration:"underline", textDecorationColor:"green", textUnderlinePosition:"under"}} className="text-center" >The Premier Vape Shop Near </h3>

                <p data-aos="zoom-out" data-aos-duration="1000" className="text-center text px-3" style={{color:"black",backgroundColor:"white",justifyContent:"left", fontSize:"16px",textAlign:"left", lineHeight:"30px"}}>
                <span >You have come to the right place if you are searching for a vape shop near me because Vape Station is Pakistan’s leading Vape Store for all your vaping needs. Our staff is always ready to assist you in finding the perfect set of products, be it devices or flavors, to suit your preferences and needs. Visit our store outlets or order online for delivery all over Pakistan.</span>
                </p>

            </div>
        </div>
        </div>
        :null
    }
    {
        props?.indi=="2"?<div className='container mt-3' style={{overflow:"hidden"}}> <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 padd-right">
        <div style={{marginBottom:"20px"}}>
                <h1 data-aos="fade-left" data-aos-duration="1000" className="text-center pakheading">Online Vape Store in Pakistan</h1><br/>
            
                {/* <h3 data-aos="fade-right" data-aos-duration="1000" style={{fontSize:"25px",fontWeight:"1000", marginBottom:"20px", textDecoration:"underline", textDecorationColor:"green", textUnderlinePosition:"under"}} className="text-center">LARGEST VAPE STORE NETWORK</h3> */}
                
                

            <p data-aos="zoom-out" data-aos-duration="1000" className="text-center text px-3 " style={{color:"black",backgroundColor:"white",justifyContent:"left", fontSize:"16px",textAlign:"left", lineHeight:"30px"}}>

            Vape House boasts one of the most extensive collections of Vapes and E-liquids available for online sale in Pakistan. Our product range encompasses Vapes, E-Cigarettes, Electronic Shisha, Digital Shishas, Pods, Mods, Tanks, Nicotine Salts, Freebase Flavours, Disposable Vapes, Basic Starter Kits, Elite Vapes, Accessories, and Spares. If you're running low on supplies, head to your favorite vape shop in Pakistan. Purchase online for delivery throughout Pakistan or visit any of our numerous vape store outlets in Karachi. With excellent customer service, we're always close to you, regardless of the distance. Our selection features top brands from the USA, UK, Europe, Malaysia, and China.
            </p>

        </div>
        </div>
        {/* <div id="" style={{marginBottom:"70px"}}>
                
                <h3 data-aos="fade-right" data-aos-duration="1000" style={{fontSize:"25px",fontWeight:"1000", marginBottom:"20px", textDecoration:"underline", textDecorationColor:"green", textUnderlinePosition:"under"}} className="text-center" >LARGEST CHAIN OF VAPE STORES</h3>

            <p data-aos="zoom-out" data-aos-duration="1000" className="text-center text px-3" style={{color:"black",backgroundColor:"white",justifyContent:"left", fontSize:"16px",textAlign:"left", lineHeight:"30px"}}>
            <span >Elite Cigs & Vaporizers is the leading vape store in Pakistan having a chain of outlets in Karachi, Lahore, Multan, Hyderabad and Sialkot. We have the largest stock of vape products from the leading international brands. Our store offer all types of POD MOD and vape juices at discounted prices. We serve only the top quality Ecigs, Vape juices like Vape MODS, portable devices, Kits and all type of accessories at an affordable price. Visit our store today at your city.
<br></br>
<p style={{margin:"20px"}}> Lahore | Multan | Bahawalpur | Sialkot
| </p></span>
            </p>

        </div> */}
       
    </div>:null
 
        

    }
        
    </>
  )
}

export default Pakistan
