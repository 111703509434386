import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./../gallary/lahore.css"
import { useEffect } from 'react';

export default function LahoreS(props) {

    // const [prodata, setProdata] = useState();
    const arryIMG=[{img:require("./../../assets/vapehouse-lhr2.jpeg"),heading:"Lahore 2"},{heading:"Lahore 3",img:require("./../../assets/vapehouse-lhr3.jpeg")},{heading:"Lahore 4",img:require("./../../assets/vapehouse-lhr4.jpeg")},{img:require("./../../assets/vapehouse-lhr2.jpeg"),heading:"Lahore 2"},{heading:"Lahore 3",img:require("./../../assets/vapehouse-lhr3.jpeg")},{heading:"Lahore 4",img:require("./../../assets/vapehouse-lhr4.jpeg")}]
useEffect(()=>{
// console.log( "clicled and render")
},[props.indi])

    const settings = {
        dots: false,
        infinite: true,
        
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        pauseOnHover: true,
        arrows: false,
        responsive: [
            {
                breakpoint: 1536,
                settings: {
                    slidesToShow: 4,
                },
            },
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 4,
                },
            },
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 4,
                },
            },
            {
                breakpoint: 1024,
                settings: {

                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 500,
                settings: {

                    slidesToShow: 2,
                },
            },
        ],
    }
  return (<>
      {/* <div>Lahore</div> */}

      <div  className="maind"  >
        <div  className='main2'>
        <Slider  {...settings}  style={{zIndex:props.indi==true? -200:1}}>
                {
            arryIMG?.map((item, index) => {
                        return (
                            <div className='product-main-body'
                             onClick={()=>{
if(props.indi==true){
    props.setIndi(false)
}else{
    props.setIndi(true)
    props.setIndexS(index)
    window.scrollTo(500, 400);
    // document.body.style.overflow = 'hidden';
    document.getElementById("root").style.overflow='visible';

// let a=[]
// for(let b=0 ;b<arryIMG.length; b++){
//     let c= arryIMG[b].img.substring(3,arryIMG[b].length)
//     a.push(c)
// }


// console.log("data of galary =====> ",arryIMG)
    props.setDataArr(arryIMG)
}
                            }} 
                         
                            
                            >
                                <div className='product_container1' id='slideId' >

                                        <img alt='123432' src={item.img} />
                                   
                                </div>
                            </div>

                        )
                    })

                }
            </Slider>



            
        </div>
        
      
      </div>

   
  </>
    


  )
}
