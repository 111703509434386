import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../components/Body/Body.css';
import { MdShoppingCart } from "react-icons/md";
import ClipLoader from "react-spinners/ClipLoader";
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AddToCart,setItemData } from '../components/Redux/Action';
import { HiMinusSm, HiPlusSm } from 'react-icons/hi';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const VariationSlider = () => {

const arrayVariation =useSelector(state=>state.ReducerVariationData);
const dispach=useDispatch();
const [prodata, setProdata] = useState();
useEffect(()=>{
if(arrayVariation!='abc'){
    setProdata(arrayVariation)
}


},[arrayVariation])

   


    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        pauseOnHover: true,
        arrows: false,
        responsive: [
            {
                breakpoint: 1536,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 1024,
                settings: {

                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 500,
                settings: {

                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 400,
                settings: {

                    slidesToShow: 1,
                },
            },
        ],
    
    }

    const dispatch = useDispatch()
    const [loader, setLoader] = useState(true);

    return (


        <>
        <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                theme="colored"
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

           <div >
            <h4>Variation Data</h4>
           </div>



        

            <Slider  {...settings} className="mx-5 px-5 mb-5">

            {
                    

                        prodata?.map((item, index) => {
                            return (
                                    <div className='product-main-body' >
                                <div className='product_container1' style={{ height:'150px',width:"120px" }}  >
                                   {
                                       item.PromoPercentage ?
                                       <span className='discount-offers'>  {item.PromoPercentage}% OFF</span> :
                                       ""
                                   }
                                    <Link to={'/productdetail'} state={{ item }} onClick={() => { window.scrollTo(0, 0);item.qty=0; dispach(setItemData(item))}}>
                                        <img alt={`${window.imagesapi}/images/ProductImages/${item.Barcode}.jpg`} src={`${window.imagesapi}/images/ProductImages/${item.Barcode}.webp`} style={{height:'80px',width:"80px"}} />
                                    </Link>

                                    <p style={{fontSize:"12px"}}>{item.Description}</p>

                                    <div className='product-cart'>
                                        <div className='product-cart1'>
                                          
                                        </div>
                                       
                                    </div>
                                </div>
                                </div>

                            )
                        }) 

                }
            </Slider> 
        </>
    );
};


export default VariationSlider;