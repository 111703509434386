
import React, { useEffect, useRef, useState } from 'react';
import './Header.css';
import { MdAccountCircle, MdFavorite, MdShoppingCart } from "react-icons/md";
import { GiHamburgerMenu } from "react-icons/gi";
import { Link, useNavigate, } from 'react-router-dom';
import Loginform from './Loginform';
import Viewcartitem from '../../Screens/Viewcartitem';
import { useSelector, useDispatch } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { PoductsApi, ChangeName, setItemData } from '../Redux/Action';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Banner from './Banner';
import { BrandPoductsApi } from "./../Redux/Action"

const Header = () => {

  const [acountname, setAcountname] = useState(window.accountname)
  const navigate = useNavigate()
  const bgcolor = useRef()
  const dispatch = useDispatch()
  const [searchPro, setSearchPro] = useState("")
  const [X, setX] = useState(false)
  const [toggleLogin, setToggleLogin] = useState(false)
  const [opencart, setOpencart] = useState(false)
  const [depData, setDepData] = useState(null);
  const [brandData, setBrandData] = useState(null);
  const [groups, setGroups] = useState([]);
  const [sbgroup, setSbgroup] = useState([])
  const [searchValue, setSearchValue] = useState('')

  const [brandDisplay, setBrandDisplay] = useState("none")
  const cartItems = useSelector(state => state.Reducer)
  const DepartmentData = useSelector(state => state.ReducerDepData)
  const getBrand = useSelector(state => state.ReducerBrandsData)
  const lengthItem = cartItems.length
  const [searchBox,setSearchBox]=useState(false)

  useEffect(() => {
    if (DepartmentData !== 'abc') {
      const updatedData = DepartmentData.map((item) => {
        item.isShown = false;
        return { ...item }
      })
      setDepData(updatedData)
    }
    if (getBrand !== 'abc') {
      const updatedData = getBrand.map((item) => {
        item.isShown = false;
        return { ...item }
      })
      setBrandData(updatedData)
    }




  }, [DepartmentData, getBrand])
  // function brand_Api() {
  //   fetch(`${window.sbapi}/api/product/ProductsByBrand?BrandCode=&FromIndex=1&ToIndex=20&BusinessId=0000000001	`).then((data) => {
  //     console.log("this is a first console of brand" + data)
  //   }).then((dat) => { console.log("this is a Brand Api" + dat) })

  // }


  const getCooki = (cookiname) => {
    let cname = cookiname + "=";
    let csplit = document.cookie.split(';');
    for (let i = 0; i < csplit.length; i++) {
      let c = csplit[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(cname) == 0) {
        return c.substring(cname.length, c.length);
      }
    }
    return "";
  }

  const searchHandler = async (key) => {
    setSearchValue(key)
    if (key.length > 2) {
      setSearchBox(true)
let a= document.getElementById('sch').style.display = "flex";
      // console.warn(`Search key ${key}`)
      let result = await fetch(`${window.sbapi}/api/Product/ProductsByName?BusinessId=0000000001&Name=${key}`);
      result = await result.json();
      let sortdata = result.Data;

      if (sortdata.length > 0) {
        const a = sortdata.map(item => {
          item.qty = 1
          item.DepartmentName = item.DepartmentCode
          return { ...item }
        })
       
        setSearchPro(a)
      }
    }

    else {
      setSearchPro("")
      // console.log('Search API No Data Found ')
    }

  }


  // console.log('getBrand', depData)

  return (

    <>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        theme="colored"
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />



      <Banner />






      <nav className='navbar navbar-dark' style={{ height: "100px" }}>
        <div className='mLogo'> <Link to={'/'}><img alt='footer' src='https://res.cloudinary.com/vape-house/image/upload/v1702742639/footer_qz9zsc.png' width={67} height={71} /></Link></div>
        <div className='logo mt-1 ' onMouseEnter={()=>{  searchHandler("")
          setSearchBox(false);}}   onClick={()=>{
          searchHandler("")
          setSearchBox(false);
        }} >
          <Link to={'/'}  onClick={()=>{
          searchHandler("")
          setSearchBox(false);
        }}>
            <img alt={'logon'} src="https://res.cloudinary.com/vape-house/image/upload/v1702742763/Logonpng_ibr9k8.png"  onClick={()=>{
          searchHandler("")
          setSearchBox(false);
        }} />
          </Link>

        </div>

        <div className='search-bar' style={{ position: 'relative',marginLeft:"-200px"
        , fontSize: "14px" }}>
          <form autoComplete='off'>        
              <input value={searchValue} type="search" autoComplete="off" autoCorrect="off" autoCapitalize="off" spellCheck="false" autoFocus="true"  className={'input'} style={{ fontSize: "14px",width:"200%" }}   placeholder='Search Product' onChange={e => searchHandler(e.target.value)} />
              </form>

        </div>

        {/* <div>
        <input value={searchValue}  onChange={e => searchHandler(e.target.value)}  class="DocSearch-Input" aria-autocomplete="both" aria-labelledby="docsearch-label" id="docsearch-input" autocomplete="off" autocorrect="off" autocapitalize="off" enterkeyhint="search" spellcheck="false" autofocus="true" placeholder="Search docs" maxlength="64" type="search" />
        </div> */}


        <span className='search-container' id='sch' >
          {searchPro ? (
            <Scrollbars style={{ height: 300 }} >
              {
                searchPro ? searchPro.map((item, key) => {
                  return (

                    <Link className='search-product-container' to={'/product-detail'} state={{ item }}>
                      <Link className='search-imag' to={'/product-detail'} 
                      onClick={() => {
                        dispatch(setItemData(item))
                        searchHandler("")
                        setSearchBox(false);
                       let a= document.getElementById('sch').style.display = 'none';

                        // let a= getDucomentById("sch");
                      }}
                        state={{ item }}
                      >

                        <img alt={`${window.imagesapi}/images/ProductImages/${item.Barcode}.webp`} src={`${window.imagesapi}/images/ProductImages/${item.Barcode}.webp`} sizes={100}  />

                      </Link>
                      <Link to={'/product-detail'} onClick={() => { dispatch(setItemData(item));
setSearchBox(false);
let a=document.getElementById('sch').style.display = 'none';
                      }} state={{ item }}>
                        <p className='item-desc' style={{ fontSize: 16 }} >{item.Description}</p>
                        <h5 className='item-desc' style={{ fontSize: 16 ,marginTop:-15}}  > Price:                    Rs. {item.UnitRetail - (item.UnitRetail * item.PromoPercentage / 100)}
{item.PromoPercentage ?<del className="oldPrice">Rs: {item.PromoPercentage ? item.UnitRetail : ("0")}</del>:null }</h5>
                   
                      </Link>
                    </Link>

                  )
                })
                  : ''
              }
            </Scrollbars>) : ""
          }

        </span>
        <div>



        </div>

        {/* Third Icon or Social Media Part */}

        <div className='social-media' onMouseEnter={()=>{
            searchHandler("")
            setSearchBox(false);
        }}>
          <ul className='social-media-desktop'>
            <li>
              <div>

                <MdAccountCircle style={{ color: 'black' }} className='my-account' onClick={() => setToggleLogin(!toggleLogin)} />
                <span style={{ position: 'relative' }}>
                  <h6 id='accountname'> </h6>

                  <h6 className='accountnamedisplay' id='accountnamedisplay' > {getCooki(window.accountname)} </h6>


                  <span className='login-container' style={{ display: toggleLogin ? 'flex' : 'none' }}>
                    <Loginform setToggleLogin={setToggleLogin} color='black' />
                  </span>
                </span>
              </div>

            </li>
            <li>
              <span className='my-favorite' onMouseEnter={() => { setToggleLogin(false); setOpencart(false) }}>
                <MdFavorite className='your-favorite' color='black' />
                <span className='batch1'>0</span>
              </span>
            </li>
            <li>
              <div>
                <span className='my-cart'>
                  <MdShoppingCart className='your-cart' onMouseEnter={() => { setToggleLogin(false) }} color={'black'} onClick={() => {
                    if (lengthItem === 0) {
                      setOpencart(false)
                      toast.warn(' Your Cart Is Empty!', {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                      })
                    }
                    else {
                      setOpencart(!opencart)
                    }
                  }
                  } />
                  <span className='batch2'>{lengthItem}</span>
                </span>
                <span style={{ position: 'relative' }}>
                  <span className='viewcart_container' onMouseLeave={() => setOpencart(false)} style={{ display: opencart ? 'flex' : 'none' }}>

                    <Viewcartitem />
                  </span>
                </span>
              </div>
            </li>
          </ul>

        </div>
      </nav>
      <Scrollbars onMouseLeave={() => setBrandDisplay("none")} style={{ zIndex: 100, position: "absolute", display: brandDisplay, justifyContent: "center", marginLeft: "14%", marginTop: "50px", backgroundColor: "white", flexDirection: "column", width: "160px", height: "230px", position: 'absolute', boxShadow: "rgba(0, 0, 0, 0.16) 4px 4px 20px" }}>

        {
          brandData?.map((item, key) => {
            return (
              <Link to={"/brand-products"} className='menu_container'
                onMouseOver={() => {
                  brandData[key].isShown = true
                  setBrandData([...brandData])

                }}
                onMouseLeave={() => {
                  brandData[key].isShown = false
                  setBrandData([...brandData])
                }}
              >
                <div className='brand-list-style' onClick={() => {
                  setBrandDisplay("none");
                  dispatch(BrandPoductsApi(item.Code))
                  localStorage.setItem("code", item.Code)

                  navigate("/brandProducts")
                }} >   {item?.Name} </div>
              </Link>
            )
          })
        }

      </Scrollbars>

      <nav className='main-menu' onMouseEnter={() => { setToggleLogin(false); setOpencart(false) }}  >
        <Link id='a123' to={'/brand-products'}
          bgcolor={bgcolor}
          onClick={() => {
            dispatch(BrandPoductsApi("all-data"))
            localStorage.setItem("code", "all-data")
          }} className='menu_container' style={{ backgroundColor: "black" }} onMouseLeave={() => document.getElementById("a123").style.backgroundColor = "black"} onMouseEnter={() =>
            document.getElementById("a123").style.backgroundColor = " #F5801E"
          }>
          <p style={{ marginTop: '10px', position: "relative" }}
            onMouseOver={() => {
              if (brandDisplay == "none") {
                setBrandDisplay("flex")
              } else {
                setBrandDisplay("none")
              }
            }}>
            BRANDS
            <img style={{ marginLeft: "5px", marginTop: "10px" }} width="10px" height="5px" alt='drop' src='https://res.cloudinary.com/vape-house/image/upload/v1702742927/drop_chyja4.png' />
          </p>
        </Link>
        <Link to={'/about-us'} onMouseEnter={() => setBrandDisplay("none")} className='menu_container'><p style={{ marginTop: '10px' }}>ABOUT US</p></Link>
        {
          depData?.map((item, index) => {
            // console.log("sdwhfuw784e", item);
            return (
              <Link to={'/original-vape-products-in-pakistan'} onMouseEnter={() => setBrandDisplay("none")} className='menu_container'
                onMouseOver={() => {
                  depData[index].isShown = true
                  setGroups(item?.Groups)
                  setDepData([...depData])

                }}
                onMouseLeave={() => {
                  depData[index].isShown = false
                  setGroups([])
                  setDepData([...depData])
                }}

                onClick={(e) => {
                  var dep = depData[index].value = item.DepartmentCode;
                  var dpgr = {
                    department: item.DepartmentName,
                    Dcode: dep,
                  }
                  dispatch(ChangeName(dpgr))
                  dispatch(PoductsApi(dep, ''))
                  e.stopPropagation();
                }}>
                <p style={{ marginTop: '10px' }}>
                  {item.DepartmentName}
                  <img style={{ marginLeft: "5px", marginTop: "10px" }} width="10px" height="5px" src='https://res.cloudinary.com/vape-house/image/upload/v1702742927/drop_chyja4.png' alt={item.DepartmentName} /></p>
                <div className='sab_menu' style={{ position: 'absolute', boxShadow: "rgba(0, 0, 0, 0.16) 4px 4px 20px" }}>
                  {
                    groups?.filter((groupItems) => groupItems?.DepartmentCode === item?.DepartmentCode)?.map((it, key) => {
                      return (
                        <>
                          <Link to={'/original-vape-products-in-pakistan'}
                            key={index}
                            onMouseOver={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              setGroups(item?.Groups)
                              setSbgroup(it?.SubGroups)
                              setDepData([...depData])
                            }}
                            onMouseLeave={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              setSbgroup([])
                              setGroups([...item?.Groups])
                              setDepData([...depData])
                            }}
                            onClick={(e) => {
                              var dep = depData[index].value = item.DepartmentCode;
                              var grup = depData[index].value = it.GroupCode;
                              var dpgr = {
                                department: item.DepartmentName,
                                Dcode: dep,
                                Gcode: it.GroupCode,
                                group: it.GroupName
                              }
                              dispatch(ChangeName(dpgr))
                              dispatch(PoductsApi(dep, grup));
                              e.stopPropagation();
                              setSbgroup([])
                              setGroups([])
                            }}
                          >
                            <p key={key}>  {it.GroupName} </p>
                            <div key={key} style={{ position: 'absolute', boxShadow: "rgba(0, 0, 0, 0.16) 4px 4px 20px", right: "-160px", zIndex: "1", marginTop: "-36px" }}>
                              {
                                sbgroup?.filter((itemss) => it?.DepartmentCode === itemss?.DepartmentCode && it?.GroupCode === itemss?.GroupCode)?.map((subg, keyss) => {
                                  // console.log("sdcdc7wec7wc", subg);
                                  return (
                                    <Link to={'/original-vape-products-in-pakistan'} key={keyss}
                                      onClick={(e) => {
                                        var dep = depData[index].value = item.DepartmentCode;
                                        var grup = depData[index].value = it.GroupCode;
                                        var subgrup = depData[index].value = subg?.SubGroupCode;
                                        var dpsubgr = {
                                          department: item.DepartmentName,
                                          Dcode: dep,
                                          Gcode: grup,
                                          group: it.GroupName,
                                          subgrup: subg?.SubGroupName,
                                          subgrupcode: subg?.SubGroupCode
                                        }
                                        dispatch(ChangeName(dpsubgr))
                                        dispatch(PoductsApi(dep, grup, subgrup));
                                        e.stopPropagation();
                                        setSbgroup([])
                                        setGroups([])
                                      }}
                                    >
                                      <p >
                                        {subg?.SubGroupName}
                                      </p>
                                    </Link>
                                  )
                                })
                              }
                            </div>
                          </Link>
                        </>
                      )
                    })

                  }

                </div>
              </Link>
            )
          })

        }

        {/* <Link to={'/gallary'} className='menu_container'><p style={{ marginTop: '10px' }}>GALLERY</p></Link> */}
        <Link to={'/contact-us'} className='menu_container'><p style={{ marginTop: '10px' }}>CONTACTS</p></Link>
      </nav>

      {/* Second Main Menu For Media Query Responsivenes */}

      {/* <hr color='#772f22' ></hr> */}

      <nav className='main-menu2'>
        {/* Hambarger Icon and onclick Function for Mian Menu Responsive  */}

        <div className='hamb' >

          <a onClick={() => {
            setX(!X)
          }} >
            <GiHamburgerMenu size='22px' color='white' align-itamm='left' />
          </a>
          <div className={X ? 'ham-display' : 'ham-not-display'} >


            <Link to={'/brand-products'} onClick={() => {
              dispatch(BrandPoductsApi("all-data"))
              localStorage.setItem("code", "all-data")
            }} className='menu_container'>
              <p style={{ position: "relative", marginBottom: "0px" }}
             

              >BRANDS</p>




            </Link>

            {
              depData?.map((item, index) => {
                return (
                  <Link to={'/original-vape-products-in-pakistan'} className='menu_container'
                 
                    onClick={(e) => {
                      var dep = depData[index].value = item.DepartmentCode;
                      dispatch(PoductsApi(dep, ''))
                      e.stopPropagation();
                      setX(!X)
                    }}
                  >
                    <p style={{ marginBottom: "0px" }}>{item.DepartmentName}</p>
                    
                  </Link>
                )
              })
            }

          </div>
        </div>

        {/* Responsive Search bar */}

        <div className='search-bar2'>
          <input className='input' type="search" placeholder='Search Product' onChange={e => searchHandler(e.target.value)} />
        </div>

        <div className='social-media2'>

          <div className='mobile_icons'>
            <MdAccountCircle className='my-account' onClick={() => setToggleLogin(!toggleLogin)} />
            <span style={{ position: 'relative' }}>
              <span className='login-container' style={{ display: toggleLogin ? 'flex' : 'none' }}>
                <Loginform setToggleLogin={setToggleLogin} />
              </span>
            </span>
          </div>
          <div className='mobile_icon'>
            <span className='my-favorite'>
              <MdFavorite className='your-favorite' />
              <span className='batch1'>0</span>
            </span>
          </div>
          <div className='mobile_icons'>
            <span className='my-cart'>
              <MdShoppingCart className='your-cart' onClick={() => setOpencart(!opencart)} />
              <span className='batch2'>{lengthItem}</span>
            </span>
            <span style={{ position: 'relative' }}>
              <span className='viewcart_container' style={{ display: opencart ? 'flex' : 'none' }}>
                <Viewcartitem />
              </span>
            </span>
          </div>

        </div>
      </nav>
    </>)
}

export default Header



