import React, { useState, useEffect } from "react";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import './ProductDetail.css'
import Review from "./Review";
import { HiMinusSm, HiPlusSm } from 'react-icons/hi';
import { MdFavoriteBorder } from 'react-icons/md';
import { TiSocialFacebook } from 'react-icons/ti';
import { RiInstagramLine } from 'react-icons/ri';
import { AiFillStar, AiOutlineStar, AiOutlineWhatsApp } from 'react-icons/ai';
import Apiproduct from "../components/Body/Apiproduct";
import { useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { AddToCart, SetQty, PoductsApi, FiltrApi, variationData, setItemData } from "../components/Redux/Action";
import { Link } from "react-router-dom";
import Allapiproduct from "../components/Body/Allapiproduct";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios, { Axios } from "axios";
import VariationSlider from "./VariationSlider";

const ProductDetail = () => {
    const [openModal, setOpenModal] = useState(false)
    const cartItems = useSelector(state => state.Reducer)
    const DepartmentData = useSelector(state => state.ReducerDepData)
    const FilterApi = useSelector(state => state.ReducerFilterData)

    const [VariationArr, setVariationArr] = useState('')
    const dataVari = useSelector(state => state.ReducerVariationData)
    const itemredux = useSelector(state => state.ReducerItemData)
    const [depData, setDepData] = useState(null);
    const [desc, setDesc] = useState(true)
    const [AppiData, setAppiData] = useState();
    const dispatch = useDispatch()
    const item2 = useLocation().state.item;
    const [item, setItem] = useState('')
    const [varBtn, setVarBtn] = useState('flex');
    const [varPart, setVarPart] = useState('none');
    const [Size, setSize] = useState([]);
    const [dp1, setdp1] = useState("block");
    const [dp2, setdp2] = useState("block");
    const [dp3, setdp3] = useState("block");
    const [dp4, setdp4] = useState("block");
    const [Images, setImages] = useState();

    const [colors, setColors] = useState('')
    const [sizeS, setSizes] = useState()
    const [flavers, setFlawers] = useState('')
    const [nicotin, setNicotin] = useState('')
    const [selectProductPops, setSelectProductPops] = useState('none')
    const [selectProductPops1, setSelectProductPops1] = useState('none')
    const [selectProductPops2, setSelectProductPops2] = useState('none')
    const [selectProductPops3, setSelectProductPops3] = useState('none')
    const [item4,setItem4]=useState()






    const [colorList, setColorList] = useState('')
    const [designsorOrhmsList, setDesignsorOrhmsList] = useState('')
    const [flavoursList, setFlavoursList] = useState('')
    const [sizesorNicotinList, setSizesorNicotinList] = useState('')

    const arraya = [{ a: "abnmkjhh", b: "12365478" }, { a: "abnujhhre", b: "00005478" }, { a: "olihmkjhh", b: "12000078" }, { a: "abnbyrhh", b: "1004008" }]

    function getUniqeElement(a) {
        let uniqArr = [];
        for (let i = 0; i < a.length; i++) {
            if (uniqArr.indexOf(a[i]) === -1) {
                uniqArr.push(a[i])
            }
        }
        return uniqArr
    }
    useEffect(() => {
      
       

// console.log("CHECK DATA IN PARAM",item2)
// setItem(item2)
        if (itemredux != "abc") {
            setItem(itemredux)
        }
        else {
            setItem(item2)
        }
    }, [itemredux])

    // useEffect(() => {

    //     if (dataVari != "abc") {
    //         setVariationArr(dataVari)
    //     }



    // }, [dataVari])
    useEffect(() => {
        ApiDAta(Api)
    }, [dataVari, itemredux])

    useEffect(()=>{
//   console.log("DATA IS AVAILABLE")
ApiDAta(Api)
    },[item4])

    const Api = `https://easyapivarivh.thevapehouse.pk/api/Product/ProductByCode?Barcode=${item.Barcode}&IsEcom=true`;
    const ApiDAta = async (url) => {



        const res = await axios.get(url)
        const it4=await res?.data?.Data
        setItem4(it4)
        const Imges = await res.data.Data.Variations
        dispatch(variationData(Imges))
        const ColorsList = await res.data.Data.ColorsList
        setColorList(ColorsList);
        const SizeofNeco = await res.data.Data.SizesorNicotinList
        setSizesorNicotinList(SizeofNeco);
        const DesignsorOrhmsList = await res.data.Data.DesignsorOrhmsList
        setDesignsorOrhmsList(DesignsorOrhmsList)
        const FlavoursList = await res.data.Data.FlavoursList
        setFlavoursList(FlavoursList);
        setImages(Imges)

        if (SizeofNeco.length == 0) {
            setdp1("none")
        }
        if (ColorsList.length == 0) {
            setdp2("none")
        }
        if (FlavoursList.length == 0) {
            setdp3("none")
        }
        if (DesignsorOrhmsList.length == 0) {
            setdp4("none")
        }
        const Productss = await res.data.Data;
        setAppiData(Productss);
        dispatch(FiltrApi(Productss))

        setSize(SizeofNeco)
    }

    const increment = (item) => {
        if (item.qty < 10) {
            item.qty += 1
            dispatch(SetQty({ ...item }))
        }
    }

    const decrement = (item) => {
        if (item.qty > 1) {
            item.qty -= 1
            dispatch(SetQty({ ...item }))
        }
    }
    useEffect(() => {

        if (DepartmentData !== 'abc') {
            const updatedData = DepartmentData.map((AppiData) => {
                AppiData.isShown = false;
                return { ...AppiData }
            })
            setDepData(updatedData)
        }

        ApiDAta(Api);
    }, [DepartmentData])
  

    return (
        <>

            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                theme="colored"
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <Header  id='windowclose'/>


            <div className='p-detail'>


                <div style={{ flexDirection: "column", justifyContent: "center" }}>
                    <div className="p-picture">

                        <img src={`${window.imagesapi}/images/ProductImages/${item.Barcode}.webp`} alt={item.Description} />

                    </div>

                    <div className="vslider" style={{ height: "100px", marginTop: "40px" }}>
                        <VariationSlider />
                    </div>
                </div>


                <div className="p-disc">
                    <h2>{item ? item.Description : ""}</h2>


                    <h3>

                        Rs. {item.UnitRetail - (item.UnitRetail * item.PromoPercentage / 100)}
{item.PromoPercentage ?<del className="oldPrice">Rs: {item.PromoPercentage ? item.UnitRetail : ("0")}</del>:null }

                        


                    </h3>


    
                    {/* <div style={{ height: "80px", display: "block", overflow: "hidden", fontSize:"14px" }}>Details:<div dangerouslySetInnerHTML={{ __html: item.Comments + '.....'}} /></div> */}

                    <h4 className="cart-size"> Unit Size: 1</h4>

                    <a className="btnV" style={{ display: varBtn, cursor:"pointer" }} onClick={() => { ApiDAta(Api); setVarPart("flex"); setVarBtn("none") }}> Select Variation </a>

                    <div style={{ margin: "10px", flexDirection: "column", display: varPart }}>

                        <div>
                            <div className="dropDownMenu" style={{ display: dp2 }} onClick={() => {

                                setColorList(FilterApi.ColorsList)

                                selectProductPops1 == "none" ? setSelectProductPops1("flex") : setSelectProductPops1("none");
                            }} >
                                {colors ? colors.Name : "Select Color"}
                                <i class="fa fa-caret-down" style={{ float: "right" }} ></i>

                            </div>

                            <div className="drop-down-menu_pops" style={{ display: selectProductPops1 }}>
                                {colorList ?
                                    colorList.map((val) => {

                                        return (<>

                                            <p className="dropdown4 dropdown-pops" onClick={() => {
                                                setSelectProductPops1("none");
                                                setColors(val);
                                                let emptya = [];
                                                let emptyB = [];

                                                let result = VariationArr.filter(obj => {
                                                    if (obj.ColorId == val.Code) {
                                                        emptya.push(obj)


                                                    }

                                                });
                                                let empt_lenght = emptya.length
                                                for (let i = 0; i < empt_lenght; i++) {
                                                    let filterSizeVariation = sizesorNicotinList.filter((obj) => {
                                                        if (obj.Code == emptya[i].DesignId) {


                                                            emptyB.push(obj)
                                                        }
                                                    })

                                                }

                                                setSizesorNicotinList(getUniqeElement(emptyB));
                                              




                                            }} >{val.Name}</p>

                                        </>)
                                    }) : ""}

                            </div>
                        </div>


                        <div>

                            <div className="dropDownMenu" style={{ display: dp1 }} onClick={() => {
                                setSizesorNicotinList(FilterApi.SizesorNicotinList)
                                selectProductPops == "none" ? setSelectProductPops("flex") : setSelectProductPops("none");
                            }} >
                             
                                {sizeS ? sizeS.Name : "Select Size or Nicotin"}
                                <i class="fa fa-caret-down" style={{ float: "right" }} ></i>
                          
                            </div>

                            <div className="drop-down-menu_pops" style={{ display: selectProductPops }}>
                                {sizesorNicotinList ?
                                    sizesorNicotinList.map((val) => {

                                        return (<>

                                            <p className="dropdown4 dropdown-pops" onClick={() => {
                                                setSelectProductPops("none");
                                                setSizes(val); 
                                                // console.warn("item data from redux" + FilterApi)

                                                let emptya = [];
                                                let emptyB = [];

                                                let result = VariationArr.filter(obj => {
                                                    if (obj.SizeId == val.Code) {
                                                        emptya.push(obj)


                                                    }

                                                });
                                                let empt_lenght = emptya.length
                                                for (let i = 0; i < empt_lenght; i++) {
                                                    let filterSizeVariation = designsorOrhmsList.filter((obj) => {
                                                        if (obj.Code == emptya[i].DesignId) {


                                                            emptyB.push(obj)
                                                        }
                                                    })

                                                }
                                                // console.log("Size o nicotin List b" + emptyB + "list a" + emptya)
                                                setDesignsorOrhmsList(getUniqeElement(emptyB));
                                      




                                            }} value="1">{val.Name}</p>

                                        </>)
                                    }) : ""}

                            </div>
                        </div>



                        <div>
                            <div className="dropDownMenu" style={{ display: dp4 }} onClick={() => {
                               
                                selectProductPops2 == "none" ? setSelectProductPops2("flex") : setSelectProductPops2("none");
                            }} >
                              
                                {nicotin ? nicotin.Name : "Select Design or ohms"}
                                <i class="fa fa-caret-down" style={{ float: "right" }} ></i>
                              
                            </div>

                            <div className="drop-down-menu_pops" style={{ display: selectProductPops2 }}>
                                {designsorOrhmsList ?
                                    designsorOrhmsList.map((val) => {

                                        return (<>

                                            <p className="dropdown4 dropdown-pops" onClick={() => {
                                                setSelectProductPops2("none");
                                                setNicotin(val); 
                                                // console.log("design or ohms" + nicotin);

                                                let emptya = [];
                                                let emptyB = [];

                                                let result = VariationArr.filter(obj => {
                                                    if (obj.DesignId == val.Code) {
                                                        emptya.push(obj)


                                                    }

                                                });
                                                let empt_lenght = emptya.length
                                                for (let i = 0; i < empt_lenght; i++) {
                                                    let filterSizeVariation = flavoursList.filter((obj) => {
                                                        if (obj.Code == emptya[i].FlavourId) {


                                                            emptyB.push(obj)
                                                        }
                                                    })

                                                }

                                                setFlavoursList(getUniqeElement(emptyB));
                                            
                                            }} >{val.Name}</p>

                                        </>)
                                    }) : ""}

                            </div>
                        </div>




                        <div>
                            <div className="dropDownMenu" style={{ display: dp3 }} onClick={() => {
                                selectProductPops3 == "none" ? setSelectProductPops3("flex") : setSelectProductPops3("none");
                            }} >
                                {flavers ? flavers.Name : "Select Flavers"}
                                <i class="fa fa-caret-down" style={{ float: "right" }} ></i>
                              
                            </div>
                            <div className="drop-down-menu_pops" style={{ display: selectProductPops3 }}>
                                {flavoursList ?
                                    flavoursList.map((val) => {

                                        return (<>
                                            <div className="dropdown4 dropdown-pops" onClick={() => {
                                                setSelectProductPops3("none");
                                                setFlawers(val);
                                                let empty = [];
                                                let varArry = VariationArr.filter((obj) => {
                                                    if (obj.FlavourId === val.Code && obj.SizeId === sizeS.Code) {
                                                        empty.push(obj)
                                                       

                                                    }
                                                })
                                                let abcd = getUniqeElement(empty)
                                                dispatch(setItemData(...abcd))
                                   

                                            }} >{val.Name}</div>

                                        </>)
                                    }) : ""}

                            </div>
                        </div>


                    </div>
                    <div className="qty">
                        <div>
                            <p>QTY:</p>
                        </div>
                        <div className="qty-main">
                            <a className="plus-a" onClick={() => increment(item)} >
                                <HiPlusSm />
                            </a>
                            <div className="qty-first">
                                <p >{item ? item.qty : ""}</p>
                            </div>
                            <a className="minas-a" onClick={() => decrement(item)} >

                                <HiMinusSm />
                            </a>

                        </div>
                        {/* <div > */}
                        <a className="btnVriation" style={{cursor:"pointer"}} onClick={() => {
                            dispatch(AddToCart(item))
                            toast.success(' Your Product Added in Cart', {
                                position: "top-center",
                                autoClose: 1000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            })
                        }}>
                            ADD TO CART </a>
                        {/* </div> */}
                    </div>

                    <h4 className="cart-size"> Product SKU: {item ? item.Barcode : ""} </h4>

                    <div className="review">
                        <AiFillStar /><AiFillStar /><AiFillStar /><AiFillStar /> <AiOutlineStar />
                        <a href="#" >
                            &nbsp; &nbsp; Add your review
                        </a>
                    </div>
                    <div className="favorite">
                        <MdFavoriteBorder />
                        <a href="#" >
                            &nbsp; ADD TO FAVOURITE
                        </a>
                    </div>
                    <h4 className="category"> CATEGORY:
                        <Link to={"/productspage"}
                            onClick={(e) => {
                                var dep = depData.value = item.DepartmentCode;
                                dispatch(PoductsApi(dep, ''))
                                e.stopPropagation();
                            }}
                        >
                            &nbsp; {item.DepartmentName}
                        </Link>
                        <Link to={'/productspage'} onClick={(e) => {
                            var dep = depData.value = item.DepartmentCode;
                            var grup = depData.value = item.GroupCode;
                            dispatch(PoductsApi(dep, grup));
                            e.stopPropagation();
                        }}
                        >
                            &nbsp; {item.GroupName}
                        </Link>
                    </h4>

                    <h4 className="share">SHARE:

                        <a href='https://www.facebook.com/vapehouse.pk/' target="_blank">
                            &nbsp; <TiSocialFacebook />
                        </a>
                        <a href='https://instagram.com/vhpakistan?igshid=YmMTA2M2Y=' target="_blank">
                            &nbsp; <RiInstagramLine />
                        </a>
                        <a href="//api.whatsapp.com/send/?phone=923006800812&text=Welcome&app_absent=0" target="_blank">
                            &nbsp; <AiOutlineWhatsApp />
                        </a>
                    </h4>
                    <p style={{ fontSize: "15px" }}>Other people want this. 15 people have this in their carts at the moment</p>

                    <div className="btn1">

                        <button className="reviews-btns" onClick={() => { setDesc(!desc) }}><p> PRODUCT DESCRIPTION </p></button>

                        <div>
                            <p onClick={() => setOpenModal(!openModal)}><button className="reviews-btns2"><p> REVIEWS </p></button> </p>
                            <span style={{ position: 'relative' }} >

                                <span className='open-review ' style={{ display: openModal ? 'flex' : 'none', }}  >
                                    <Review closeModal={setOpenModal} onClick={() => setOpenModal(false)} />
                                </span>
                            </span>
                        </div>
                    </div>



                </div>


            </div>
            <div style={{margin: 20, paddingLeft: 10, fontSize: 14 }}>
            <div><b>Details:<br/></b></div>
            {console.log("DATA OF ITEM 4 ======>>>",JSON.stringify(item4) )}
          
                <div style={{ margin: 20, paddingLeft: 10, fontSize: 14 }} dangerouslySetInnerHTML={{ __html: item4?.Comments?item4?.Comments:  item4?.Comments}} />
                </div>


            <div className="related-product">
                <h1 style={{ fontSize: 18, width: "auto" }}>RELATED PRODUCTS</h1>
            </div>
            <div >
                {/* <div>
    <VariationSlider item={VariationArr} />
</div> */}

                <Allapiproduct indi={"details"}   />
            </div>



            <Footer />
        </>


    )
}

export default ProductDetail;