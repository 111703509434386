import React, { useEffect } from 'react'
import Home from './Home'
import Header from '../components/Header/Header'
import Footer from '../components/Footer/Footer'
import { Link } from 'react-router-dom'
import ClipLoader from "react-spinners/ClipLoader";
import { useState } from 'react';
import "./../Screens/gallary.css"
import LahoreS from './gallary/LahoreS'
import {AiFillCaretLeft,AiFillCaretRight } from "react-icons/ai";

export default function Gallary() {
const arryIMG=[{img:require("./../assets/vapehouse-lhr2.jpeg"),heading:"Lahore 2"},{heading:"Lahore 3",img:require("./../assets/vapehouse-lhr3.jpeg")},{heading:"Lahore 4",img:require("./../assets/vapehouse-lhr4.jpeg")}]
const [count,setCount]=useState(0)
const [indi,setIndi]= useState(false)
const [dataArr,setDataArr] =useState([])
const [indexs,setIndexS]=useState(0)
// useEffect(()=>{
// console.log("ABC")
// },[])

  return (
    <>
        {/* <div> */}
       
    {/* </div> */}
  <div >
        <Header  />
        <div id='aqeel' 
//         onClick={()=>{
// var a=document.getElementById("aqeel")
// console.log("cliking dada",a)
//         }} 
        style={ { display:indi? "flex":"none",marginBottom:"-105%", alignItems:"center", background:"gray",zIndex:1000,height:"1000px", width:"100%",  justifyContent:"center"}}> 
        <div onClick={()=>{
            if(indexs >0){
                setIndexS(indexs-1)
            }
           
        }} style={{zIndex:1000}} >
            <AiFillCaretLeft size={50} color='red'/>
        </div>
           <div onClick={()=>{setIndi(false);document.getElementById("root").style.overflow='visible'; }} style={{zIndex:1000,borderRadius:10, border:"1px solid rgb(214, 212, 212)",display:"flex", justifyContent:"center",alignItems:"center", padding:"10px", height:"500px",width:"700px"}}>
     
           <img src={dataArr[indexs]?.img} alt='dataArr[indexs]?.img' style={{height:"480px",width:"680px"}} />
           </div>
           <div style={{zIndex:1000}} onClick={()=>{
              if(indexs <dataArr.length-1){
                setIndexS(indexs+1)
            }
           }}>
            <AiFillCaretRight size={50} color='red'/>
        </div>
   
            {/* <h1>this is modal </h1> */}

        </div>

    
<div className='galaryMain' style={{justifyContent:"center",zIndex:-1, alignItems:"center"}}>
<div style={ { flexDirection:"row", display:"flex", justifyContent:"center" ,width:"100%", alignItems:"center"}}>
                                        <h1 style={{zIndex:-1}}>Lahore</h1>
                                   
                                    </div>
  
                                    <LahoreS setDataArr={setDataArr} setIndexS={setIndexS} indi={indi} setIndi={setIndi}/>

</div>
      




<div className='galaryMain' style={{justifyContent:"center",zIndex:-1, alignItems:"center"}}>
<div style={ { flexDirection:"row", display:"flex", justifyContent:"center" ,width:"100%", alignItems:"center"}}>
                                        <h1 style={{zIndex:-1}}>Multan</h1>
                                   
                                    </div>
                                    <LahoreS indi={indi} setIndi={setIndi} />
</div>
           
       
            





<div className='galaryMain' style={{justifyContent:"center",zIndex:-1, alignItems:"center"}}>
<div style={ { flexDirection:"row", display:"flex", justifyContent:"center" ,width:"100%", alignItems:"center"}}>
                                        <h1 style={{zIndex:-1}}>Gujranwala</h1>
                                        
                                    </div>
                                    <LahoreS  indi={indi} setIndi={setIndi}/>
</div>

            
      






<div className='galaryMain' style={{justifyContent:"center",zIndex:-1, alignItems:"center"}}>
<div style={{justifyContent:"center",zIndex:-1,display:"flex", flexDirection:"column", alignContent:"center", alignItems:"center"}}>
                                        <h1 style={{zIndex:-1}}>Sialkot</h1>
                                   
                                    </div>
                                    <LahoreS indi={indi} setIndi={setIndi}/>
</div>

          
      




   
        <Footer />
    </div>


    
    
    </>
  
  )
}
